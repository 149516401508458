import { PaperDemos } from '@demos/core';
import { ThemeColors } from "../../../../src/components/ThemeColors/ThemeColors";
import { ThemeColorExtendDemo, ThemeFontsExtendDemo, NestedProvidersDemo, PrimaryColorDemo, ThemeOverrideDemo } from '@docs/demos';
import * as React from 'react';
export default {
  PaperDemos,
  ThemeColors,
  ThemeColorExtendDemo,
  ThemeFontsExtendDemo,
  NestedProvidersDemo,
  PrimaryColorDemo,
  ThemeOverrideDemo,
  React
};