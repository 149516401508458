import { JsonInputDemo, ColorInputDemo, WrappedInputsDemo, RegularInputsDemo } from '@docs/demos';
import { InputWrapperDemos } from '@demos/core';
import * as React from 'react';
export default {
  JsonInputDemo,
  ColorInputDemo,
  WrappedInputsDemo,
  RegularInputsDemo,
  InputWrapperDemos,
  React
};