import { UseDebouncedValueBaseDemo, useIntervalHook } from '@docs/demos';
import { SegmentedControlDemos, PopoverDemos, DividerDemos, GroupDemos, GridDemos, CenterDemos, TabsDemos, RingProgressDemos, BadgeDemos, NumberInputDemos } from '@demos/core';
import * as React from 'react';
export default {
  UseDebouncedValueBaseDemo,
  useIntervalHook,
  SegmentedControlDemos,
  PopoverDemos,
  DividerDemos,
  GroupDemos,
  GridDemos,
  CenterDemos,
  TabsDemos,
  RingProgressDemos,
  BadgeDemos,
  NumberInputDemos,
  React
};